import React from 'react';

import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';

const UserEditTitle = ({ record }) => {
    return <span> Edit User: {record ? `"${record.fullName}"`   : '' }</span>
}
export const UserEdit = props => (
    <Edit title={<UserEditTitle />} {...props}>
        <SimpleForm>
            <TextInput source="fullName" />
            <TextInput source="emailAddress" type="email" />
            <ReferenceArrayInput label="Roles" source="roles" reference="roles" allowEmpty>
                <SelectArrayInput optionText="roleName" />
            </ReferenceArrayInput>
            <TextInput source="newPassword" type="password" />
            <TextInput source="confirmNewPassword" type="password" />
        </SimpleForm>
    </Edit>
);