import React from 'react';

import { 
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceArrayField,
    Datagrid,
    FunctionField,
    Button,
    GET_MANY,
} from 'react-admin';

import { handle } from '../core/providers/dataProvider';
import { Link } from 'react-router-dom';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        'width': '80vw',
    },
}
const CustomShowButton = (props) => {
    const record = props.record;
    return (
        <Button 
            component={Link}
            to={{
                pathname: `/stores/${record.id}/show`,
                search: `?brand=${props.id}`
            }}
            label="Show"
        >
            <ImageEye />
        </Button>
    )
};

const RetailerTitle = ({record}) => {
    return <span>Store : {record ? `"${record.name}"` : ''}</span>;
}

async function GetDeviceCount(props){
    const query = await handle(GET_MANY, "devices").then(documents => {
        const devices = documents.data.filter(device => device.brand === props.id);
        return devices;
    })

    return query.length;
}

export const BrandShow = withStyles(styles)(({classes, ...props}) => {
    let count;
    GetDeviceCount(props).then(_count => count = _count);
    return(
        <Show title={<RetailerTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <ReferenceArrayField label="Stores" reference="stores" source="stores" className={classes.root}>
                    <Datagrid> 
                        <TextField source="name" />
                        <TextField source="city" />
                        <FunctionField label="Device Count" render={() => count !== undefined ? count : 0} />
                        <CustomShowButton {...props} />
                    </Datagrid>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
});
