import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    DisabledInput,
} from 'react-admin';

// TODO: This is basically a fake camera right now, we need a way of connecting this to a real camera.

export const CameraCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <DisabledInput source="store"/>
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);