import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';

export const StoreCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="locationCode" />
            <TextInput source="name" />
            <TextInput source="address" />
            <TextInput source="city" />
            <TextInput source="postcode" />
            <TextInput source="country" />
        </SimpleForm>
    </Create>
);