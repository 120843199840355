import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,

} from 'react-admin';


export const BrandCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/brands">
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);