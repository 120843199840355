import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ShowButton,
    EditButton,
} from 'react-admin';

import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';

export const StoreList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="city" />
            <FunctionField label="Device Count" render={record => record.devices ? record.devices.length : 0} />
            {/* Render compliance level based on % of compliant devices */}
            <FunctionField label="Compliance" render={record => <TrueIcon />} />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
)