import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
const styles = {
    header: {
        position: "static",
        backgroundColor: "rgba(0,174,239,0.72)",
    },
    h2: {
        paddingLeft: 15,
        color: "white",
    },
    img: {
        height: 22,
        width: "auto",
    }
};

const MyAppBar = withStyles(styles)(({classes, ...props}) => (
    <AppBar {...props} className={classes.header}>
        <Toolbar>
            <img src="http://www.outform.com/wp-content/uploads/2016/10/logo-svg.svg" alt="outform logo" className={classes.img} />
            <Typography variant="title" id="react-admin-title" className={classes.h2} />
        </Toolbar>
    </AppBar>
));

export default MyAppBar;