import React from 'react';
import {
    List, 
    Datagrid,
    TextField,
    EditButton,
    CardActions,
    CreateButton,
    ExportButton,
} from 'react-admin';

const roleLocation = {
    hash : "",
    pathName: "/roles",
    search: "",
    state: undefined
}

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const RoleActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <CreateButton basePath="/roles" record={data} />
        {/*  TODO: fix export button funtionality */}
        <ExportButton resource="roles" />
    </CardActions>
);


export const RoleList = (props) => (
    <List 
        {...props} 
        resource="roles"
        location={roleLocation}
        actions={<RoleActions />}
        >
        <Datagrid>
            <TextField source="roleName" />
            <TextField source="roleDesc" />
            <EditButton basePath="/roles" />
        </Datagrid>
    </List>
);
