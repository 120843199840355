import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import { firebaseConfig } from '../config';
import authProvider from '../providers/authProvider';
import dataProvider from '../providers/dataProvider';
import Dashboard from '../../dashboard/dashboard';

import UserIcon from '@material-ui/icons/Person';

import { 
  UserListTabbed,
  UserEdit,
  UserCreate,
} from '../../users';

import {
  RoleEdit,
  RoleCreate,
} from '../../roles'

import {
  BrandList,
  BrandEdit,
  BrandCreate,
  BrandShow
} from '../../brands';

import {
  StoreList,
  StoreEdit,
  StoreCreate,
  StoreShow,
} from '../../stores';

import {
  CameraCreate,
  CameraShow,
} from '../../cameras';

// TODO: Make an index.js file
import MyLayout from '../../layout/layout';
export class App extends Component {
  render() {
    return (
      <Admin
        title="Outform Display Compliance"
        dataProvider={dataProvider(firebaseConfig)}
        authProvider={authProvider()}
        dashboard={Dashboard}
        appLayout={MyLayout}
      >
        <Resource name="users" list={UserListTabbed} edit={UserEdit} create={UserCreate} icon={UserIcon}/>
        <Resource name="roles" edit={RoleEdit} create={RoleCreate} />
        <Resource name="brands" list={BrandList} edit={BrandEdit} create={BrandCreate} show={BrandShow} />
        <Resource name="stores" list={StoreList} edit={StoreEdit} create={StoreCreate} show={StoreShow} />
        <Resource name="devices" />
        <Resource name="cameras" create={CameraCreate} show={CameraShow} />
        <Resource name="images" />
      </Admin>
    );
  }
}
