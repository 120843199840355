import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    email,
    required,
    minLength,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';

const passwordsMatch = (value, allValues, props) => (
    (allValues.password !== allValues.confirmPassword ? "Passwords do not match!" : undefined)
);
const validatePasswords = [required(), minLength(6, "Password has to be atleast 6 characters!"),passwordsMatch];
const validateEmail = [required("Please enter an email address"),email("Please enter an valid email address")];

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/users">
            <TextInput source="fullName" />
            <TextInput source="emailAddress" type="email" validate={validateEmail} />
            <ReferenceArrayInput label="Roles" source="roles" reference="roles" allowEmpty>
                <SelectArrayInput optionText="roleName" />
            </ReferenceArrayInput>
            <TextInput source="password" type="password" validate={validatePasswords}/>
            <TextInput source="confirmPassword" type="password" validate={validatePasswords} />
        </SimpleForm>
    </Create>
);