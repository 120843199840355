import React from 'react'
import {
    ShowController,
    Show,
    SimpleShowLayout,
    TextField,
    ImageField,
    ReferenceArrayField,
    Datagrid,
    DateField,
    Button,
    UPDATE,
    ReferenceField,
    BooleanField,
} from 'react-admin';

import { handle } from '../core/providers/dataProvider';

import { withStyles } from '@material-ui/core/styles';

import Canvas from '../core/components/Canvas';

const styles = {
    image: {
        width: 512,
        minHeight: 340.5,
    },
    cardActionStyle : {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    },
    root: {
        'width' : '80vw',
    }
}

const CameraTitle = ({record}) => {
    return <span>Store : {record ? `"${record.name}"` : ''}</span>;
}

const SetButton = (props) => {
    return (
        <Button 
            onClick={async(event) => {
                await handle(UPDATE, "cameras", props);
                await handle(UPDATE, "images", props);
                window.location.reload();
            }
        }>
            <p>Set</p>
        </Button>
    )
};


// TODO: if the camera doesn't have a compliant image set, display a list of images and let the user select which one the the compliant one.
export const CameraShow = withStyles(styles)(({classes, ...props}) => {
    return (
        <ShowController title={<CameraTitle />} {...props}>
            {controllerProps => (
                <Show {...props}>
                    <SimpleShowLayout>
                        {controllerProps.record && controllerProps.record.compliantImage && (
                            [
                                <TextField source="name" key="name"/>,
                                <ReferenceField label ="Compliant Image" source="compliantImage" reference="images" key="compliantImage" linkType={false}>
                                    <Canvas />
                                    {/* TODO: To the right hand side of this i need to use the custom canvas element and draw an image next to the one above */}
                                </ReferenceField>,
                                <ReferenceArrayField sort={{field: "timestamp", order: "DESC"}} source="images" reference="images" key="reference" label=" " className={classes.root}>
                                    <Datagrid>
                                        <TextField source="name" sortable={false} />
                                        <ImageField source="imageURL" label="Images" sortable={false} />
                                        <DateField source="timestamp" showTime sortable={false}  />
                                        <BooleanField source="isCompliant" sortable={false} />
                                    </Datagrid>
                                </ReferenceArrayField>
                            ]
                        )}
                        {controllerProps.record && !controllerProps.record.compliantImage && (
                            [
                                <TextField key="name" source="name" />,
                                <p key="title"> Please select a valid compliant image </p>,
                                <ReferenceArrayField reference="images" source="images" key="reference" className={classes.root}>
                                    <Datagrid>
                                        <Canvas />
                                        <DateField source="timestamp" showTime />
                                        <SetButton />
                                    </Datagrid>
                                </ReferenceArrayField>
                            ]
                        )}
                    </SimpleShowLayout>
                </Show>
            )}

        </ShowController>

    )
});
