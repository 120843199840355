import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    LongTextInput,
} from 'react-admin';


export const RoleCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/users">
            <TextInput source="roleName" />
            <LongTextInput source="roleDesc" />
            {/* PERMISSIONS NEED TO BE ADDED TOO! */}
        </SimpleForm>
    </Create>
);