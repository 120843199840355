import React from 'react';

import { 
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    FunctionField,
    ShowButton,
    ReferenceField,
} from 'react-admin';
import { parse } from 'query-string';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InformationIcon from '@material-ui/icons/Person';

import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';

import CardActions from '@material-ui/core/CardActions';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import ContentAdd from '@material-ui/icons/Add';
import Canvas from '../core/components/Canvas';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    image: {
        width: 512,
        minHeight: 340.5,
    },
    cardActionStyle : {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    },
    root: {
        'width': '80vw',
    }
}

const DeviceField = withStyles(styles)(({classes, ...props}) => (
    <ReferenceManyField label="" reference="devices" target="store" className={classes.root} {...props}>
        <Datagrid>
            <TextField source="name" />
            <FunctionField label="NFC" render={record => <span> { record.nfcID == null ? <FalseIcon /> : <TrueIcon /> }</span>} />
            <FunctionField label="Correct Rotation" render={record => <TrueIcon />} />
        </Datagrid>
        {/* TODO: Current Status */}
    </ReferenceManyField>
));

const DeviceFieldFiltered = withStyles(styles)(({classes, ...props}) => (
    <ReferenceManyField label="" reference="devices" target="store" source="devices" className={classes.root} filter={{brand: this.state.searchParam}} {...props}>
        <Datagrid>
            <TextField source="name" />
            {/* TODO: Need to make it so that if it's moved of the NFC this value changes */}
            <FunctionField label="NFC" render={record => <span> { record.nfcID == null ? <FalseIcon /> : <TrueIcon /> }</span>} />
            {/* TODO: Need to make both of these live update */}
            <FunctionField label="Correct Rotation" render={record => <TrueIcon />} />
        </Datagrid>
    </ReferenceManyField>
));

const CameraField = withStyles(styles)(({classes, ...props}) =>  (
    <ReferenceManyField reference="cameras" target="store" className={classes.root} {...props}>
        <Datagrid>
            <ReferenceField label="Compliant Image" source="compliantImage" reference="images" linkType={false}>
                <Canvas />
            </ReferenceField>
            <TextField source="name" label="Camera" />
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
));

class StoreShow extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tab: 0,
            searchParam : null,
            deviceIsCorrect: true,
        }
        this.cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            float: 'right',
        };
    }

    componentDidMount(){
        if(this.props.location.search){
            const _searchParam = parse(this.props.location.search);
            this.setState((state, props) => {
                return{ searchParam: _searchParam.brand }
            })
        }   
    }

    handleChange = (event, value) => {
        this.setState( () => {
            return { tab : value }
        })
    }
    
    cameraOptions = (basePath, data, resource) => (   
        <CardActions style={this.cardActionStyle}>
            <Button
                component={Link}
                to={{
                    pathname: '/cameras/create',
                    state: { record: { store: basePath.data.id } },
                    label: 'CREATE',
                }}
            >
                {/* Change the color of the button */}
                <ContentAdd /> Create
            </Button>
        </CardActions>
    );
    render(){
        const { tab } = this.state;
        return(
            <div>
                <Tabs 
                    value={tab} 
                    onChange={this.handleChange}
                    fullWidth
                    centered
                >
                    <Tab icon={<InformationIcon />} label="Information" />
                    <Tab icon={<InformationIcon />} label="Devices" />
                    <Tab icon={<InformationIcon />} label="Images" />
                </Tabs>

                {tab === 0 && (
                    <Show {...this.props}>
                        <SimpleShowLayout>
                            <TextField source="name" />
                            <TextField source="address" />
                            <TextField source="city" />
                            <TextField source="postcode" />
                            <TextField source="country" />
                        </SimpleShowLayout>
                    </Show>

                )}

                {tab === 1 && (
                    <Show {...this.props}>
                        <SimpleShowLayout>
                            {!this.state.searchParam &&
                                <DeviceField />
                            }
                            {this.state.searchParam && 
                                <DeviceFieldFiltered />
                            }
                        </SimpleShowLayout>
                    </Show>
                )}
                {tab === 2 && (
                    // This will be the camera page, need to render canvas here to display the images.
                    <Show actions={<this.cameraOptions />}{...this.props}>
                        <SimpleShowLayout>
                            <CameraField />
                        </SimpleShowLayout>
                    </Show>
                )}
            </div>
        );
    }
}

export default StoreShow;