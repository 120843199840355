export const firebaseConfig = {
  apiKey: 'AIzaSyAPbgONxDYAjEEgTthqrW-K0u9huCDhJcQ',
  authDomain: 'merchandise-management.firebaseapp.com',
  databaseURL: 'https://merchandise-management.firebaseio.com',
  projectId: 'merchandise-management',
  storageBucket: 'merchandise-management.appspot.com',
  messagingSenderId: '245376576224',
};

export const firebaseAdminConfig = {

};