import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';
import firebase from 'firebase';

const baseConfig = {
  userCollection: 'users',
  userAdminProp: 'isAdmin',
  handleAuthStateChange: async (auth, config) => {
    if (!auth || !auth.user) throw new Error('Failed to authenticate');

    const snapshot = await firebase
      .firestore()
      .collection(config.userCollection)
      .where('emailAddress', '==', auth.user.email)
      .get();

    if (snapshot.empty) throw new Error('User does not exist');
  },
};

export default (config = {}) => {
  config = { ...baseConfig, ...config };
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

  const firebaseLoaded = () =>
    new Promise(resolve => {
      firebase.auth().onAuthStateChanged(resolve);
    });

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      return firebase.auth().signOut();
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload();
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded();

      if (!firebase.auth().currentUser) {
        throw new Error('Not authenitcated');
      }

      return true;
    }

    if (type === AUTH_LOGIN) {
      const { username, password, alreadySignedIn } = params;
      let auth = firebase.auth().currentUser;

      if (!auth || !alreadySignedIn) {
        auth = await firebase
          .auth()
          .signInWithEmailAndPassword(username, password);
      }

      return config.handleAuthStateChange(auth, config);
    }

    return false;
  };
};
