import firebase from 'firebase';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

export default config => {
  firebase.initializeApp(config);
  firebase.firestore().settings({ timestampsInSnapshots: true });

  return handle;
};

export const handle = async (type, resource, params) => {
  const collection = firebase.firestore().collection(resource);

  switch (type) {
    case GET_LIST: {
      const { sort } = params;
      const { page, perPage } = params.pagination;

      let snapshot;
      if (sort.field !== 'id') {
        snapshot = await collection
          .orderBy(sort.field, sort.order.toLowerCase())
          .get();
      } else snapshot = await collection.get();

      const data = snapshot.docs
        .map(formatDoc)
        .slice((page - 1) * perPage, page * perPage - 1);

      return { total: snapshot.docs.length, data };
    }
    case GET_ONE: {
      const data = await collection.doc(params.id).get().then(formatDoc);
      return { data: data };
    }
    case UPDATE: {
      if(resource === "cameras"){
        const cameraID = params.record.name.slice(0, 2);
        collection.doc(cameraID).update({
          compliantImage: params.record.name,
        })
        const data = await collection.doc(params.record.name).get().then(formatDoc);
        return {data : data};
      }else if(resource === "images"){
        collection.doc(params.record.name).update({
          isCompliant: true,
        })
        const data = await collection.doc(params.record.name).get().then(formatDoc);
        return {data : data};
      }else{
        collection.doc(params.id).set(params.data);
        const data = await collection.doc(params.id).get().then(formatDoc);
        return {data : data};
      }
    }
    case CREATE: {
      let createdDocRef = null;
      if(resource === "cameras"){
        const storageRef = firebase.storage().ref();
        const downloadURL = await storageRef.child('compliance_images/' + params.data.imgURL.title).put(params.data.imgURL.rawFile).then(snapshot => {
          const downloadURL = snapshot.ref.getDownloadURL().then(downloadURL => {
            return downloadURL;
          });
          return downloadURL;
        });
        const camera = {
          name: params.data.name,
          store: params.data.store,
          imgURL: downloadURL,
        }

        createdDocRef = collection.doc(params.data.name.toLowerCase());
        createdDocRef.set(camera);
        const data = await collection.doc(createdDocRef.id).get().then(formatDoc);
        return{ data: data };
      }else if(resource === "users"){
        createdDocRef = collection.doc();
        createdDocRef.set(params.data);
        const data = await collection.doc(createdDocRef.id).get().then(formatDoc);
        return { data: data };
      }else{
        if(resource === "stores"){
          createdDocRef = collection.doc(params.data.name.toLowerCase());
          params.data.devices = [];
        }

        createdDocRef = collection.doc(params.data.name.toLowerCase());
        createdDocRef.set(params.data);
        const data = await collection.doc(createdDocRef.id).get().then(formatDoc);
        return {data : data};
      }
    }
    case UPDATE_MANY:
      // const query = {
      //   filter: JSON.stringify({ id: params.ids }),
      // };
      // url = `${apiUrl}/${resource}?${stringify(query)}`;
      // options.method = 'PATCH';
      // options.body = JSON.stringify(params.data);
      return [];
    case DELETE:
      // TODO: Handle Deletion of items.
      // url = `${apiUrl}/${resource}/${params.id}`;
      return {};
    case DELETE_MANY:
      // TODO: Handle Deletion of many items.
      // const query = {
      //   filter: JSON.stringify({ id: params.ids }),
      // };
      // url = `${apiUrl}/${resource}?${stringify(query)}`;
      return [];
    case GET_MANY: {
      const data = await collection.get().then(formatDocs);
      return { data : data }
    }
    case GET_MANY_REFERENCE: {
      if(resource === "devices" || resource === "cameras"){
        if(params.filter.brand !== undefined){
          // This isn't very nice but i couldn't think of another way of doing it right now.
          const data = await collection.where('brand', '==', params.filter.brand).get().then(snapshot => {
            const newSnapshot = snapshot.docs.filter(doc => params.id.includes(doc.id));
            let _data = newSnapshot.map(doc => ({id: doc.id, ...doc.data()}));
            return _data;
          })
          return { data : data, total : data.length }
        }else{
          const data = await collection.where('store', '==', params.id).get().then(formatDocs);
          return { data : data, total : data.length }
        }
      }
      return {}
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

const formatDoc = doc => ({
  id: doc.id,
  ...doc.data(),
});

const formatDocs = Query => {
  let data = Query.docs.map((element => ({
    id: element.id,
    ...element.data()
  })))
  return data;
};