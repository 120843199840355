import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    FunctionField,
    ShowButton,
    EditButton,
} from 'react-admin';


export const BrandList = props => (
    <List {...props} title="Brands">
        <Datagrid>
            <TextField source="name" />
            <FunctionField label="Store Count" render={record => record.stores ? record.stores.length : 0} />
            {/* Need to add a compliance level*/}
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
