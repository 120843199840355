import React from 'react';

import {
    TextField,
    List,
    Datagrid,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    EmailField,
    EditButton,
} from 'react-admin';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import UserIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/People';

import { RoleList } from '../roles';

class UserListTabbed extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            value: 0,
        }
    }

    handleChange = (event, value) => {
        this.setState({value});
    }

    render() {
        const { value } = this.state;

        // TODO: MAKE THE LISTS SEPERATE COMPONENTS!
        return (
            <div>
                <Tabs 
                    value={value} 
                    onChange={this.handleChange}
                    fullWidth
                    centered
                >
                    <Tab icon={<UserIcon />} label="Users" />
                    <Tab icon={<GroupIcon />} label="Roles" />
                </Tabs>

                {value === 0 &&  (
                    <List {...this.props}>
                        <Datagrid>
                            <TextField source="fullName" />
                            <EmailField source="emailAddress" />
                            <ReferenceArrayField label="Roles" reference="roles" source="roles">
                                <SingleFieldList>
                                    <ChipField source="roleName" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <EditButton />
                        </Datagrid>
                    </List>
                )}
                {/* TODO: pass the custom create/edit components to the list */}
                
                {value === 1 && (<RoleList {...this.props} />)}               
            </div>
        );
    }
}
export default UserListTabbed;