import React from 'react';

import {
    Edit,
    SimpleForm,
    TextInput,
    LongTextInput,
} from 'react-admin';

const RoleEditTitle = ({ record }) => {
    return <span> Edit group: {record ? `"${record.roleName}"` : ''}</span>
}
export const RoleEdit = props => (
    <Edit title={<RoleEditTitle />} {...props}>
        <SimpleForm>
            <TextInput source="roleName" />
            <LongTextInput source="roleDesc" label="Role Description" />
            {/* Edit perms here too!! */}
        </SimpleForm>
    </Edit>
);