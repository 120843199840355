import React, { Component } from 'react'

export class Canvas extends Component {
    constructor(props){
        super(props);
        this.state = {
            image: props.record.imageURL,
            imageData: props.record.imageData,
        }
    }

    componentDidMount(){
        //TODO: Before this, make the call to the google API to get image information.
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        // Need to create a new img and set the URL to this.state.imgURL
        let img = new Image();
        img.onload = () => {
            canvas.width = 512;
            canvas.height = 340.5;

            ctx.drawImage(img, 0, 0, 512, 340.5);
            for(let i = 0; i < this.state.imageData.length; i++){
                ctx.beginPath();
                const startingPos = this.state.imageData[i].boundingPoly.normalizedVertices[0];
                ctx.moveTo(startingPos.x * canvas.width, startingPos.y * canvas.height);
                for(let j = 1; j < this.state.imageData[i].boundingPoly.normalizedVertices.length; j++){
                    let pos = this.state.imageData[i].boundingPoly.normalizedVertices[j];
                    ctx.lineTo(pos.x * canvas.width, pos.y * canvas.height);
                }
                ctx.lineTo(startingPos.x * canvas.width, startingPos.y * canvas.height);
                ctx.strokeStyle = '#ff0000';
                ctx.stroke();
            }
        }
        img.src = this.state.image;
    }
    render() {
        return (
            <canvas ref="canvas" />
        )
    }
}

export default Canvas;
